<template>
  <el-main>
    <div class="user-info">
      <div class="title">
        <div style="display: flex; align-items: center">
          1、用户信息
          <div class="level">{{ info.level_name }}</div>
        </div>
        <!-- <div>
          <el-button type="primary" size="small">编辑信息</el-button>
          <el-button type="primary" size="small">清退</el-button>
        </div> -->
      </div>
      <div class="info">
        <div class="item">
          分销员信息：
          <el-image :src="info.avatar"></el-image>
          {{ info.nickname }}
        </div>
        <div class="item">
          手机号码：
          {{ info.mobile }}
        </div>
        <div class="item">
          用户来源：
          <img :src="info.from_icon" alt="" />
        </div>
        <div class="item">
          注册时间：
          {{ info.date }}
        </div>
        <div v-if="info.parent_nickname" class="item">
          邀请方昵称：
          {{ info.parent_nickname }}
        </div>
        <div v-if="info.parent_mobile" class="item">
          邀请方手机号：
          {{ info.parent_mobile }}
        </div>
        <div class="item" v-for="(item, index) in content" :key="index">
          {{ item.name }}：
          <template v-if="item.type == 3 && item.name == '工作地址'">
            <span v-for="val in item.value" :key="val">{{ val }}</span>
          </template>
          <template v-if="item.type == 3 && item.name == '收货地址'">
            <span v-for="val in item.value" :key="val">{{ val }}</span>
          </template>
          <span v-else-if="item.type == 3 && item.name == '签署时间'">{{ item.value.year }}-{{ item.value.month }}-{{ item.value.sun }}</span>
          <el-image v-else-if="item.type == 5 || item.type == 6 || item.name == '签名'" style="width: 60px; height: 50px" :src="item.value" alt="" :preview-src-list="[item.value]"></el-image>
          <span v-else>{{ item.value }}</span>
        </div>
        <div class="item" v-if="contract">
          签订合同：
          <el-link :href="contract" type="primary" target="_blank">电子合同</el-link>
        </div>
      </div>
    </div>
    <div class="achievement">
      <div class="title">2、业绩概况</div>
      <el-table :data="achievement" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <el-table-column prop="sales_amount" label="累计销售金额（元）" align="center"></el-table-column>
        <el-table-column prop="order_count" label="累计订单数" align="center"></el-table-column>
        <el-table-column prop="invite_count" label="累计邀请分销员数" align="center">
          <template v-slot="{ row }">
            {{ row.invite_count }}
            <el-button @click="$router.push({ path: '/extension/distribution/relation', query: { step: 2, id: info.id } })" style="margin-left: 10px" type="text">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="user_count" label="累计客数" align="center">
          <template v-slot="{ row }">
            <el-button type="text">{{ row.user_count }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="commission">
      <div class="title">3、佣金统计</div>
      <el-table :data="commission" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <el-table-column prop="commission_amount" label="累计佣金金额（元）" align="center">
          <template v-slot="{ row }">
            {{ row.commission_amount }}
            <el-button @click="$router.push({ path: '/extension/distribution/clearing', query: { nickname: info.nickname } })" style="margin-left: 10px" type="text">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="direct_commission_amount" label="直接佣金（元）" align="center">
          <template v-slot="{ row }">
            {{ row.direct_commission_amount }}
            <el-button @click="$router.push({ path: '/extension/distribution/clearing', query: { nickname: info.nickname, type: 1 } })" style="margin-left: 10px" type="text">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="indirect_commission_amount" label="间接佣金（元）" align="center">
          <template v-slot="{ row }">
            {{ row.indirect_commission_amount }}
            <el-button @click="$router.push({ path: '/extension/distribution/clearing', query: { nickname: info.nickname, type: 2 } })" style="margin-left: 10px" type="text">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="lockingUser">
      <div class="title">4、潜在用户</div>
      <el-table :data="lockingUserList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <el-table-column prop="nickname" label="其下用户昵称"  align="center">
          <template v-slot="{ row }">
            <userInfo :name="row.nickname" :avatar="row.avatar"></userInfo>
          </template>
        </el-table-column>
        <el-table-column prop="mini_openid" label="OPENID" align="center"></el-table-column>
        <el-table-column prop="locking_time" label="关系锁定时间" align="center">
          <template v-slot="{ row }">{{ getDateformat(row.locking_time) }}</template>
        </el-table-column>
      </el-table>
      <Paging v-if="Paging > 5" :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    </div>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import userInfo from '@/components/userInfo';
import Paging from '@/components/paging';
export default {
  components: {
    userInfo,
    Paging
  },
  data() {
    return {
      info: {},
      content: [],
      achievement: [],
      commission: [],
      contract: '',
      lockingUserList: [],
      page: 1,
      rows: 5,
      total_number: 0,
    };
  },
  created() {
    let info = JSON.parse(this.$route.query.info);
    this.achievement = [
      {
        sales_amount: info.sales_amount,
        order_count: info.order_count,
        invite_count: info.invite_count,
        user_count: info.user_count,
      },
    ];
    this.commission = [
      {
        commission_amount: info.commission_amount,
        direct_commission_amount: info.direct_commission_amount,
        indirect_commission_amount: info.indirect_commission_amount,
      },
    ];
    this.info = info;
    this.$axios
      .post(this.$api.distribution.distributorInfo, {
        user_id: this.$route.query.id,
      })
      .then(res => {
        if (res.code == 0) {
          if (res.result.content) {
            let content = JSON.parse(res.result.content);
            this.content = content;
          }
        }
      });
    this.getLockingUserList();
    this.$axios
      .post(this.$api.distribution.contract, {
        user_id: this.$route.query.id,
      })
      .then(res => {
        if (res.code == 0) {
          this.contract = res.result;
        }
      });
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getLockingUserList();
    },
    getLockingUserList() {
      this.$axios
        .post(this.$api.distribution.lockingUserList, {
          page: this.page,
          rows: this.rows,
          id:this.info.id
        })
        .then(res => {
          if (res.code == 0) {
            console.log(res);
            this.lockingUserList = res.result.list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  font-size: 14px;
  .title {
    border-bottom: 1px solid #ebeef5;
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .level {
      margin-left: 20px;
      color: #409eff;
      padding: 5px 15px;
      border: 1px solid #409eff;
    }
  }
  .info {
    display: flex;
    padding-left: 40px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    .item {
      display: flex;
      align-items: center;
      margin-right: 50px;
      margin-bottom: 10px;
      .el-image {
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }
    }
  }
  .el-table {
    margin-bottom: 20px;
  }
}
</style>